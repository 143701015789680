import React, {useMemo, useState} from 'react';
import {
  FILM_ORDER_STATUS_TYPE,
  FILM_ORDER_STATUS_TYPE_DISPLAY,
  FILM_STATUS_TYPE,
  FILM_STATUS_TYPE_DISPLAY,
} from '../constants';
import moment from 'moment';
import {BlockSelector} from '../Components/BlockSelector';

export const useFilmStatusFilter = ({filmList = [], style = {}}) => {
  const [activeFilmStatus, setActiveFilmStatus] = useState(
    FILM_STATUS_TYPE.started,
  );
  const filmListFilterByFilmStatus = useMemo(() => {
    if (activeFilmStatus === FILM_STATUS_TYPE.started) {
      return filmList.filter((f) => {
        return moment(f.openingDate).isBefore(moment(new Date()));
      });
    } else if (activeFilmStatus === FILM_STATUS_TYPE.coming) {
      return filmList.filter((f) =>
        moment(f.openingDate).isAfter(moment(new Date())),
      );
    }
  }, [activeFilmStatus, filmList]);

  const filmStatusFilter = (
    <BlockSelector
      style={style}
      active={activeFilmStatus}
      setActive={setActiveFilmStatus}
      options={FILM_STATUS_TYPE_DISPLAY.map((f) => ({
        name: f.title,
        value: f.key,
      }))}
    />
  );

  return {
    activeFilmStatus,
    filmStatusFilter,
    filmListFilterByFilmStatus,
  };
};
