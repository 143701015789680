import styled from 'styled-components';
import React, {Fragment} from 'react';
import {SectionHeader} from './SectionHeader';
import {navigate} from 'gatsby';
import {FilmScrollSection} from '../../Components/FilmScrollSection';
import moment from 'moment';

export const HomeDesktopContent = (props) => {
  const {filmList} = props;

  return (
    <Fragment>
      <RowSection>
        <SectionHeader
          title="現正熱映"
          caption="movies"
          button={{
            title: '更多電影',
            onClick: () => {
              navigate('/film-list');
            },
          }}
        />
        <FilmScrollSection
          filmList={filmList.filter((f) => {
            return moment(f.openingDate).isBefore(moment(new Date()));
          })}
        />
      </RowSection>
    </Fragment>
  );
};

const RowSection = styled.section`
  display: flex;
  padding: 60px 0 60px 108px;
  & > .news-list-container {
    display: flex;
    max-width: calc(100vw - 320px);
    flex-wrap: wrap;
  }
`;
