import React, {useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {Button, Dropdown, Menu} from 'antd';
import {Link, navigate} from 'gatsby';
import {useOutlet} from 'reconnect.js';
import ReactDelighters from '../../Components/ReactDelighters';
import logo from '../../../static/images/app_attlogo2.png';
import {Breakpoints, Color, SITE_LINKS_CONFIG} from '../../constants';
import {Menu as MenuIcon} from '@styled-icons/material';
import {Facebook, Instagram, Line} from '@styled-icons/fa-brands';
import {Text12} from '../../widget';
import {useFilmList} from '../../Hooks/useFilmList';
import {HomeDesktopContent} from './HomeDesktopContent';
import {HomeMobileContent} from './HomeMobileContent';
import Spinner from '../../Components/Spinner';
import Carousel from 'react-slick';
import ACES from '../../images/ACES.png';
import HONEYQ from '../../images/HONEY_Q.png';
import SUSEE from '../../images/SUSEE.png';
import {useBannerList} from '../../Hooks/useBannerList';
import {goTo} from '../../Utils/LinkingUtil';
import {useAnnouncementList} from '../../Hooks/useAnnouncementList';
import * as moment from 'moment';
import useModal from '../../Hooks/useModal';
import {delay} from '../../App';

const getScheduleResult = (list) => {
  const now = moment(new Date());
  return list
    .filter((a) => a.isPublished)
    .filter((a) => {
      const start = moment(new Date(a.startDate));
      const end = moment(new Date(a.endDate));
      return now.isBetween(start, end);
    })
    .sort((a, b) => a.priority - b.priority);
};

function Landing(props) {
  const [dimension] = useOutlet('dimension');
  const {isLoading: isFilmListLoading, filmList} = useFilmList({
    cinemaId: 1001,
  });
  const {bannerList, isLoading: isBannerListLoading} = useBannerList();
  const [dragging, setDragging] = useState(false);
  const CarouselSettings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    showArrow: false,
    autoPlay: true,
    beforeChange: () => {
      setDragging(true);
    },
    afterChange: () => {
      setDragging(false);
    },
  };
  const {modalRef, modalElem} = useModal();

  const {
    announcementList,
    isLoading: isAnnouncementListLoading,
  } = useAnnouncementList();

  const targetAnnouncement = useMemo(() => {
    if (isAnnouncementListLoading) {
      return null;
    }
    const result = getScheduleResult(announcementList);
    return result.length > 0 ? result[0] : null;
  }, [announcementList, isAnnouncementListLoading]);

  useEffect(() => {
    if (!targetAnnouncement) {
      return;
    }
    const open = async () => {
      await delay(1500);
      modalRef.current.open();
      modalRef.current.set({
        elem: (
          <div style={{minHeight: 500, paddingTop: 20}}>
            <img
              src={targetAnnouncement.imgUrl}
              style={{width: '100%', height: 200}}
              alt={'announcement'}
            />
            <div
              style={{color: Color.orange, fontSize: 20, fontWeight: 'bold'}}>
              {targetAnnouncement.title}
            </div>
            <div>{targetAnnouncement.context}</div>
          </div>
        ),
      });
    };
    open();
  }, [modalRef, targetAnnouncement]);

  if (isFilmListLoading || isBannerListLoading || isAnnouncementListLoading) {
    return <Spinner />;
  }

  return (
    <ReactDelighters>
      <NavBar hasBorder bgColor="#fff">
        <img src={logo} alt="logo" />

        <div style={{flex: 1}} />

        <div className="links">
          {SITE_LINKS_CONFIG.map((l, i) => (
            <Button key={i} type="text">
              <Link to={l.route}>{l.title}</Link>
            </Button>
          ))}
          <Button type="text" onClick={() => navigate('/profile')}>
            會員專區
          </Button>
        </div>

        <Dropdown
          className="dropdown"
          overlay={
            <Menu>
              {SITE_LINKS_CONFIG.map((l, i) => (
                <Menu.Item
                  key={i}
                  onClick={async () => {
                    await navigate(l.route);
                  }}>
                  {l.title}
                </Menu.Item>
              ))}
              <Menu.Divider />
              <Menu.Item
                onClick={async () => {
                  await navigate('/profile');
                }}>
                會員專區
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}>
          <MenuIcon size={25} color={Color.black} />
        </Dropdown>
      </NavBar>

      <Carousel {...CarouselSettings}>
        {getScheduleResult(bannerList).map((b, i) => (
          <HeroBannerSection
            onClick={async (e) => {
              if (dragging) {
                return;
              }
              await goTo({linkType: b.linkType, linkValue: b.linkValue});
            }}
            key={i}
            image={b.imgUrl}
          />
        ))}
      </Carousel>

      <Wrapper>
        {dimension.rwd === 'desktop' && (
          <HomeDesktopContent filmList={filmList} />
        )}

        {dimension.rwd === 'mobile' && (
          <HomeMobileContent filmList={filmList} />
        )}
      </Wrapper>

      {[ACES, HONEYQ, SUSEE].map((b, i) => (
        <HeroBannerSection key={i} image={b} />
      ))}

      <div style={{padding: '0 15px', margin: '10px 0'}}>
        <div style={{marginBottom: 10}}>
          <div
            style={{
              color: Color.orange,
              fontSize: 20,
              fontWeight: 'bold',
            }}>
            【ACES 王牌廳】
          </div>
          <div style={{fontSize: 14}}>
            台灣首座4K
            120幀高規格影廳，放膽挑戰全台影城放映規格，帶給您超乎想像的觀影視野。
          </div>
        </div>
        <div style={{marginBottom: 10}}>
          <div style={{color: Color.orange, fontSize: 20, fontWeight: 'bold'}}>
            【HONEY Q 情人廳】
          </div>
          <div style={{fontSize: 14}}>
            全台首創情人主題式影廳，提供給戀人們專屬隱密觀影空間體驗。
          </div>
        </div>
        <div style={{marginBottom: 10}}>
          <div style={{color: Color.orange, fontSize: 20, fontWeight: 'bold'}}>
            【SUSEE 舒適廳】
          </div>
          <div style={{fontSize: 14}}>
            全台最舒服影廳首選，給你如家一般放鬆愜意之感受，大大提升每排間距，絕對超越全台影廳排距規格，獨家打造一個舒適觀影的影廳。
          </div>
        </div>
      </div>

      <Footer>
        <img src={logo} alt="logo" />
        <Text12 className="copy-right">
          Copyright © 2021 ATT Cinema. All Rights Reserved.
        </Text12>
        <div className="social-links">
          <button className="social-link">
            <Line />
          </button>
          <button className="social-link">
            <Instagram />
          </button>
          <button className="social-link">
            <Facebook />
          </button>
        </div>
      </Footer>
      {modalElem}
    </ReactDelighters>
  );
}

const Wrapper = styled.div`
  margin-top: 64px;
  & > section {
    position: relative;
    overflow-x: hidden;
  }
  @media screen and (max-width: ${Breakpoints.md}px) {
    padding: 16px 20px;
    margin-top: 0;
  }
`;

const NavBar = styled.div`
  position: fixed;
  background-color: ${(props) => props.bgColor};
  top: 0;
  left: 0;
  height: 64px;
  width: 100vw;
  padding: 16px 85px;
  display: flex;
  align-items: center;
  z-index: 1;
  transition: 200ms;
  & > img {
    width: 144px;
    height: 33px;
    object-fit: cover;
  }
  & button {
    padding: 0 10px;
  }
  & > .dropdown {
    display: none;
  }
  @media screen and (max-width: ${Breakpoints.md}px) {
    padding: 16px 20px;
    & > .links {
      display: none;
    }
    & > .dropdown {
      display: block;
    }
  }
`;

const Footer = styled.div`
  height: 100px;
  width: 100%;
  padding: 16px 85px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > img {
    width: 144px;
    height: 33px;
  }
  & > .social-links {
    & > button.social-link {
      margin: 0 10px;
      cursor: pointer;
      width: 40px;
      height: 40px;
      border: 1px solid ${Color.black_10};
      border-radius: 50%;
      background-color: ${Color.white};
      transition: all 200ms linear;
      & > svg {
        width: 20px;
        height: 20px;
        font-weight: bold;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  & > .copy-right {
    text-align: center;
  }

  @media screen and (max-width: ${Breakpoints.lg}px) {
    flex-direction: column;
    padding: 16px 20px;
    height: unset;
    & > .copy-right {
      margin: 10px 0;
    }
  }
`;

const HeroBannerSection = styled.section`
  padding: 80px;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: ${({image}) => `url(${image})`};
  background-position: center;
  background-size: cover;
  background-color: orange;
  & > h2 {
    font-size: 32px;
    color: white;
  }
  & > p {
    font-size: 18px;
    color: #ccc;
  }

  @media screen and (max-width: ${Breakpoints.sm}px) {
    min-height: 180px;
  }
  @media screen and (max-width: ${Breakpoints.md}px) {
    min-height: 300px;
  }
`;

export default Landing;
