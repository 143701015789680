import {Color} from '../../constants';
import React from 'react';
import styled from 'styled-components';

export const Stepper = ({
  stepList,
  currentFilmIdxOfScrollingFilmList,
  setCurrentFilmIdxOfScrollingFilmList,
}) => (
  <StyledStepper currentIndex={currentFilmIdxOfScrollingFilmList}>
    {stepList.map((i, index) => (
      <div
        onClick={() => {
          setCurrentFilmIdxOfScrollingFilmList(i.index);
        }}
        key={index}
        className="step"
        style={{
          backgroundColor:
            currentFilmIdxOfScrollingFilmList === i.index
              ? Color.orange
              : Color.black_30,
        }}
      />
    ))}
  </StyledStepper>
);
const StyledStepper = styled.div`
  display: flex;
  position: absolute;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
  & > .step {
    width: 30px;
    height: 6px;
    border-radius: 3px;
    margin: 5px;
  }
`;
