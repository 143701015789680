import {LINK_TYPES} from '../constants';
import {navigate} from 'gatsby-link';

export const goTo = async ({linkType, linkValue}) => {
  switch (linkType) {
    case LINK_TYPES.book:
      await navigate(`/book?id=${linkValue}`);
      break;
    case LINK_TYPES.custom:
      window.open(linkValue);
      break;
    default:
      window.open(linkValue);
      break;
  }
};
