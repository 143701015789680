import styled from 'styled-components';
import React from 'react';
import {SectionHeader} from './SectionHeader';
import {navigate} from 'gatsby';
import FilmItem from './FilmItem';
import Carousel from 'react-slick';
import * as R from 'ramda';
import {NextArrow, PrevArrow} from '../../Components/Arrows';
import {useFilmStatusFilter} from '../../Hooks/useFilmStatusFilter';

const StyledHomeMobileContent = styled.div`
  width: 100%;

  & > section.news {
    margin-bottom: 20px;

    & > .news-list {
      display: flex;
      overflow-x: auto;
    }
  }

  & > section.films {
    width: 100%;
    margin-bottom: 20px;
    & .slick-slider {
      & .right,
      .left {
        opacity: 0.7;
        :active {
          opacity: 1;
        }
      }
      & .right {
        z-index: 1;
        right: -30px !important;
      }
      & .left {
        z-index: 1;
        left: -30px !important;
      }
    }

    & .film-list {
      display: flex !important;
      flex-direction: row;
      flex-wrap: wrap;
      position: relative;
      justify-content: space-between;
      width: 100%;
    }
  }
`;

export const HomeMobileContent = (props) => {
  const {filmList} = props;
  const {filmStatusFilter, filmListFilterByFilmStatus} = useFilmStatusFilter({
    filmList,
  });
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow style={{zIndex: 1}} />,
    prevArrow: <PrevArrow style={{zIndex: 1}} />,
  };
  return (
    <StyledHomeMobileContent>
      <section className="films">
        <SectionHeader
          title="院線電影"
          caption="movies"
          button={{
            title: '更多電影',
            onClick: async () => {
              await navigate('/film-list');
            },
          }}
        />

        {filmStatusFilter}

        <Carousel {...settings}>
          {R.splitEvery(4, filmListFilterByFilmStatus).map((fp, index) => (
            <div key={index} className="film-list">
              {fp.map((f, i) => (
                <FilmItem
                  key={i}
                  film={f}
                  onClick={async () => {
                    await navigate(`/film?id=${f.id}`);
                  }}
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    padding: 0,
                    margin: '10px 0',
                  }}
                />
              ))}
            </div>
          ))}
        </Carousel>
      </section>
    </StyledHomeMobileContent>
  );
};
