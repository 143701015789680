import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Stepper} from '../Templates/Landing/Stepper';
import FilmItem from '../Templates/Landing/FilmItem';
import {navigate} from 'gatsby';
import styled from 'styled-components';
import {Color} from '../constants';
import {NextArrow, PrevArrow} from './Arrows';

const StyledFilmScrollSection = styled.div`
  position: relative;

  & > .film-list-container {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    max-width: ${({listPage}) => (listPage ? '100%' : 'calc(100vw - 320px)')};

    ::-webkit-scrollbar {
      display: none;
    }
  }

  & button.circle.left {
    left: ${({listPage}) => (listPage ? '20px' : '-80px')};
  }

  & button.circle.right {
    right: 50px;
  }
`;

export function FilmScrollSection(props) {
  const {filmList, style = {}, listPage = false} = props;

  const filmListScrollingRef = useRef(null);
  const filmListRef = useRef([]);
  const [
    currentFilmIdxOfScrollingFilmList,
    setCurrentFilmIdxOfScrollingFilmList,
  ] = useState(0);

  const stepList = useMemo(() => {
    const totalLength = filmList.length;
    const cloneFilmListWithIdx = filmList.map((f, idx) => ({...f, index: idx}));
    if (totalLength <= 5) {
      return cloneFilmListWithIdx;
    }
    let acc;
    if (currentFilmIdxOfScrollingFilmList < 2) {
      acc = cloneFilmListWithIdx.slice(0, 5);
    } else if (currentFilmIdxOfScrollingFilmList > totalLength - 3) {
      acc = cloneFilmListWithIdx.slice(totalLength - 5, totalLength);
    } else {
      acc = cloneFilmListWithIdx.slice(
        currentFilmIdxOfScrollingFilmList - 2,
        currentFilmIdxOfScrollingFilmList + 3,
      );
    }
    return acc;
  }, [currentFilmIdxOfScrollingFilmList, filmList]);

  useEffect(() => {
    const item = filmListRef.current[currentFilmIdxOfScrollingFilmList];
    const node = filmListScrollingRef.current;
    node.scroll(item ? item.offsetLeft - 200 : 0, 0);
  }, [currentFilmIdxOfScrollingFilmList]);
  return (
    <StyledFilmScrollSection listPage={listPage} style={style}>
      {currentFilmIdxOfScrollingFilmList > 0 && (
        <PrevArrow
          onClick={() => {
            setCurrentFilmIdxOfScrollingFilmList((pre) => pre - 1);
          }}
        />
      )}
      {currentFilmIdxOfScrollingFilmList < filmList.length - 1 && (
        <NextArrow
          onClick={() => {
            setCurrentFilmIdxOfScrollingFilmList((pre) => pre + 1);
          }}
        />
      )}
      <Stepper
        currentFilmIdxOfScrollingFilmList={currentFilmIdxOfScrollingFilmList}
        setCurrentFilmIdxOfScrollingFilmList={
          setCurrentFilmIdxOfScrollingFilmList
        }
        stepList={stepList}
      />

      <div ref={filmListScrollingRef} className="film-list-container">
        {filmList.map((f, idx) => (
          <FilmItem
            onClick={() => {
              navigate(`/film?id=${f.id}`);
            }}
            ref={(node) => {
              filmListRef.current[idx] = node;
            }}
            key={idx}
            style={{margin: '0 10px'}}
            film={f}
          />
        ))}
      </div>
    </StyledFilmScrollSection>
  );
}
